:root {
  --colorD1: hsl(237, 82%, 4%);
  --colorL1: hsl(140, 10%, 94%);
  --colorP1: hsl(295, 78%, 55%);
  --colorP2: hsl(281, 78%, 55%);
  --colorP3: hsl(290, 64%, 30%);
  --colorS1: hsl(245, 37%, 52%);
  --colorT1: hsl(169, 61%, 59%);
  --colorT2: hsl(179, 80%, 27%);
  --fontHeading: 'Orbitron', sans-serif;
  --fontBody: 'Montserrat', sans-serif;
}
body {
  background-color: var(--colorD1);
  color: var(--colorL1);
  margin: 0;
  font-family: var(--fontBody);
  line-height: 1.65;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--colorP1);
  margin: 2.75rem 0 1.05rem;
  font-family: var(--fontHeading);
  font-weight: 600;
  line-height: 1.15;
}
h1 {
  margin-top: 0;
  font-size: 3.052em;
}
h2,
.h2 {
  font-size: 2.441em;
}
h3 {
  font-size: 1.953em;
}
h4 {
  font-size: 1.563em;
}
h5 {
  font-size: 1.25em;
}
small {
  font-size: 0.8em;
}
p {
  margin-bottom: 1.15rem;
}
a {
  color: var(--colorT2);
  text-decoration: none;
  transition: color ease-in-out 250ms;
}
a:hover {
  color: var(--colorT1);
  text-decoration: underline;
}
.App,
main {
  display: flex;
  flex-direction: column;
}
.App {
  min-height: 100vh;
}
header,
footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
footer {
  text-align: right;
}
.container {
  padding-right: 2rem;
  padding-left: 2rem;
}
main {
  flex: 1;
  justify-content: center;
  align-items: center;
}
.tagline {
  color: var(--colorL1);
  font-size: 1.25em;
  text-transform: uppercase;
  letter-spacing: .4em;
}
.stroke {
  height: 3px;
  background: rgb(109,27,125);
  background: linear-gradient(90deg, rgba(109,27,125,1) 0%, rgba(173,50,230,1) 50%, rgba(215,50,230,1) 100%);
}
.text-center {
  text-align: center;
}
.logo-text {
  height: 2rem;
}
.logo-icon {
  height: 2rem;
}
svg {
  max-width: 100%;
}